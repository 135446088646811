export const CSSAcdn = class {
	constructor(option = {}) {
		const defaultClassNames = {
			switch: 'open',
		};
		this.classNames = defaultClassNames;
		if ('switchClass' in option) this.classNames.switch = option.switchClass;
		this.cloneElms = [];
	}
	open(elm) {
		if (elm.classList.contains(this.classNames.switch)) {
			return;
		}
		const clone = elm.cloneNode(true);
		const cloneWrap = document.createElement('div');
		cloneWrap.style.height = 0;
		cloneWrap.style.overflow = 'hidden';
		cloneWrap.insertAdjacentElement('beforeend', clone);
		clone.style.opacity = 0;
		clone.style.visibility = 'hidden';
		clone.style.height = '';
		clone.style.maxHeight = '';
		clone.classList.add(this.classNames.switch);
		elm.insertAdjacentElement('afterend', cloneWrap);

		if (getComputedStyle(clone).transitionDuration === '0s') {
			elm.classList.add(this.classNames.switch);
			cloneWrap.parentNode.removeChild(cloneWrap);
			return;
		}
		this.cloneElms.push(cloneWrap);

		setTimeout(() => {
			const _removeStyle = (e) => {
				if (e.propertyName !== 'height') {
					return;
				}
				this.removeStyle(e.target);
				e.target.removeEventListener('transitionend', _removeStyle);
			};
			elm.style.height = this.getHeight(clone);
			elm.classList.add(this.classNames.switch);
			elm.addEventListener('transitionend', _removeStyle);
		}, 50);
	}
	close(elm) {
		if (!elm.classList.contains(this.classNames.switch)) {
			return;
		}
		elm.style.height = this.getHeight(elm);
		setTimeout(() => {
			elm.style.height = '';
			elm.classList.remove(this.classNames.switch);
		}, 10);
	}
	toggle(elm) {
		if (elm.classList.contains(this.classNames.switch)) {
			this.close(elm);
		} else {
			this.open(elm);
		}
	}
	removeStyle(elm) {
		elm.style.height = '';
		this.removeClone();
	}
	removeClone() {
		if (!this.cloneElms.length) {
			return
		}
		for (let i = 0; i < this.cloneElms.length; i++) {
			this.cloneElms[i].parentNode.removeChild(this.cloneElms[i]);
		}
		this.cloneElms = [];
	}
	getHeight(elm) {
		return getComputedStyle(elm).getPropertyValue('height');
	}
};
